import React, { useEffect } from 'react';
import { useGlobalState, useGlobalStateUpdate } from '../Context/GlobalState';
import './Skills.scss';

const Skills = (props) => {
  const visited = useGlobalState();
  const toggleVisited = useGlobalStateUpdate();

  useEffect(() => {
    if (!visited) {
      toggleVisited();
    }
  }, [visited, toggleVisited]);

  setTimeout(() => {
    document.querySelectorAll('.chart-skill').forEach((skill) => {
      skill.style.height = skill.getAttribute('data-level') + '%';
    });
  }, 500);

  function loadMore(e) {
    document.querySelectorAll('.otherSkills li.hidden').forEach((skill) => {
      skill.classList.remove('hidden');
    });
    document.querySelector('.load-more').style.display = 'none';
  }

  return (
    <section id='skills-content'>
      <span className='skills-intro'>
        {/* Listing <strong>skills</strong> without a chart is really boring,{' '}
        <span style={{ whiteSpace: 'nowrap' }}>so . . .</span> */}
        I hate these graphs too, but it helps me remember what tools I've used
        😂
      </span>
      <div className='chart-wrapper'>
        {/* removed y axis labels */}
        <ul className='chart-levels'>
          {/* <li>Can teach it</li> */}
          <li>Experienced</li>
          <li>Practiced it</li>
          <li>Learned it</li>
        </ul>
        <ul className='bar-container chart-skills'>
          <li className='chart-skill' data-level='95'>
            <span className='chart-skill-text '>React</span>
          </li>
          <li className='chart-skill' data-level='85'>
            <span className='chart-skill-text '>TypeScript</span>
          </li>
          <li className='chart-skill' data-level='88'>
            <span className='chart-skill-text '>Next.js</span>
          </li>
          <li className='chart-skill' data-level='93'>
            <span className='chart-skill-text '>PostCSS</span>
          </li>
          <li className='chart-skill' data-level='90'>
            <span className='chart-skill-text '>Tailwind</span>
          </li>
          <li className='chart-skill' data-level='85'>
            <span className='chart-skill-text '>SQL</span>
          </li>
          <li className='chart-skill' data-level='95'>
            <span className='chart-skill-text '>Wordpress</span>
          </li>
          <li className='chart-skill' data-level='85'>
            <span className='chart-skill-text '>PHP</span>
          </li>
          <li className='chart-skill' data-level='95'>
            <span className='chart-skill-text '>Git</span>
          </li>
        </ul>
      </div>

      <div className='otherSkills'>
        <ul>
          <li>Radix UI</li>
          <li>Shadcn</li>
          <li>TanStack Query</li>
          <li>TanStack Table</li>
          <li>React-Router</li>
          <li>SASS</li>
          <li>Styled Components</li>
          <li>Bootstrap</li>
          <li>Sanity.io</li>
          <li>GraphQL</li>
          <li>Prisma</li>
          <li>PostgreSQL</li>
          <li>SQL Server Management Studio</li>
          <li>React Native</li>
          <li>Expo</li>
          <li>Redux</li>
          <li>PLaywright</li>
          <li>Cypress</li>
          <li>Vercel</li>
          <li>Netlify</li>
          <li>Chakra UI</li>
          <li>Gatsby</li>
          <li>GitHub actions</li>
          <li>Material UI</li>
          <li>Webpack</li>
          <li>Node</li>
          <li>Express</li>
          <li>Python</li>
          <li>Flask</li>
          <li>Django</li>
          <li>Postman</li>
          <li>pnpm</li>
          <li>vite</li>
          <li>Hubspot</li>
          <li>FontAwesome</li>
          <li>Figma</li>
          <li>Advanced Custom Fields</li>
          <li>SEO</li>
          <li>Google Analytics</li>
          <li>Google Tag Manager</li>
          <li>Google Optimize</li>
          <li>Google Search Console</li>
          <li>Hotjar</li>
          <li>Microsoft Clarity</li>
          <li>Monday.com</li>
          <li>ClickUp</li>
          <li className='hidden'>jQuery</li>
          <li className='hidden'>Firebase</li>
          <li className='hidden'>C#</li>
          <li className='hidden'>Mocha</li>
          <li className='hidden'>Chai</li>
          <li className='hidden'>Phantom</li>
          <li className='hidden'>Mongoose</li>
          <li className='hidden'>AWS Cognito</li>
          <li className='hidden'>Emotion</li>
          <li className='hidden'>FuseJS</li>
          <li className='hidden'>SwiperJS</li>
          <li className='hidden'>Framer Motion</li>
          <li className='hidden'>React-Spring</li>
          <li className='hidden'>React-Select</li>
          <li className='hidden'>Google Maps API</li>
          <li className='hidden'>Formik</li>
          <li className='hidden'>Yup</li>
          <li className='hidden'>Dbeaver</li>
          <li className='hidden'>SQLAlchemy</li>
          <li className='hidden'>Google Ads</li>
          <li className='hidden'>FB ads</li>
          <li className='hidden'>SEM Rush</li>
          <li className='hidden'>Screaming Frog</li>
          <li className='hidden'>Google Docs</li>
          <li className='hidden'>Salesforce</li>
          <li className='hidden'>Insightly</li>
          <li className='hidden'>Xd</li>
          <li className='hidden'>Photoshop</li>
          <li className='hidden'>Zeplin</li>
          <li className='hidden'>Lightroom</li>
          <li className='hidden'>Smooth Scrollbar</li>
          <li className='hidden'>Fly.io</li>
          <li className='hidden'>Kinsta</li>
          <li className='hidden'>Local by Flywheel</li>
          <li className='hidden'>Squarespace</li>
          <li className='hidden'>Mailchimp</li>
          <li className='hidden'>Account Management</li>
          <li className='hidden'>Project Management</li>
          <li className='hidden'>Control Panel</li>
          <li className='hidden'>myPHPAdmin</li>
          <li className='hidden'>Active Collab</li>
          <li className='hidden'>Asana</li>
          <li className='hidden'>Basecamp</li>
        </ul>

        <button
          className='load-more'
          onClick={loadMore}
          aria-label='Load more skills'
          title='Load more skills'
        >
          Load More
        </button>
      </div>
    </section>
  );
};

export default Skills;
